<script lang="ts" setup>
import VWrapper from "@magnit/core/src/components/VWrapper/VWrapper.vue";

import {
  parseBduiNode,
  type IBduiNode,
  type IParseResult,
  type IModifier,
  NodeTypes,
  ModifierTypes,
} from "~/layers/bdui/services/parser";

const TITLE_FONT_SIZE = 32;
const TITLE_MARGIN_TOP = 50;
const TITLE_MARGIN_BOTTOM = 18;

const storesStore = useStoresStore();

const { requestBduiShelf } = bduiApi();

const layout = ref<IParseResult | null>(null);
const isLoading = ref<boolean>(false);

const hasShelf = computed<boolean>(() => !!layout.value?.children.length);

const double = (n = 0): number => n * 2;

const normalizeTitleForWeb = (node: IBduiNode): void => {
  const title = node.children.find((child: IBduiNode) => child.type === NodeTypes.Text);
  if (title) {
    if (title?.style?.fontSize) title.style.fontSize = TITLE_FONT_SIZE;
    title.modifier = title.modifier.map((mode: IModifier) => {
      if (mode.type === ModifierTypes.Padding) {
        if (mode.top !== undefined && mode.top > 0) mode.top = TITLE_MARGIN_TOP;
        if (mode.start !== undefined) mode.start = double(mode.start);
      }

      return mode;
    });
  }

  const spacer = node.children.find((child: IBduiNode) => child.type === NodeTypes.Spacer);
  if (spacer?.modifier[0].height) spacer.modifier[0].height = TITLE_MARGIN_BOTTOM;
};

const increaseMargin = (node: IBduiNode): void => {
  const wrapper = node.children.find((child: IBduiNode) => child.type === NodeTypes.Box);
  if (!wrapper) {
    return;
  }

  const shelf = wrapper.children[0]?.children[0];
  if (shelf) {
    shelf.modifier = shelf.modifier.map((mode: IModifier) => {
      if (mode.type === ModifierTypes.Padding) {
        mode = {
          ...mode,
          top: double(mode.top),
          end: double(mode.end),
          start: double(mode.start),
          bottom: double(mode.bottom),
        };
      }

      return mode;
    });
  }
};

const normalizeShelf = (node: IBduiNode): void => {
  normalizeTitleForWeb(node);
  increaseMargin(node);
};

const init = async (): Promise<void> => {
  try {
    isLoading.value = true;
    const response = await requestBduiShelf();

    // Временный костыль. Подгоняем мобильную верстку под веб.
    // На перспективу бдуй должен отдавать разную разметку для мобилы/веба
    normalizeShelf(response.layout.root as IBduiNode);

    layout.value = parseBduiNode(response.layout.root, {
      productRoute: Routes.Product,
      shopCode: storesStore.requestShopCode,
      shopType: storesStore.requestShopType as string,
    });
  } finally {
    isLoading.value = false;
  }
};

onMounted(init);
</script>

<template>
  <BduiShelfSkeleton v-if="isLoading" />
  <VWrapper v-else-if="!isLoading && hasShelf" class="bdui-shelf">
    <BduiNode :node="layout!" />
  </VWrapper>
</template>

<style lang="postcss" scoped>
.bdui-shelf {
  margin-top: var(--pl-unit-x8);
  margin-bottom: var(--pl-unit-x8);
  border-radius: var(--pl-unit-x5);
  overflow: hidden;
}
</style>
