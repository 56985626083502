import { parseAttributes } from "./attributes";
import type { IBduiNode, IParseParams, IParseResult } from "./types";
import { Generic, ModifierTypes, NodeTypes } from "./types";
import { images } from "./maps";
import CartStepper from "~/layers/bdui/components/Generic/CartStepper.vue";
import FavoriteButton from "~/layers/bdui/components/Generic/FavoriteButton.vue";

const parseComponent = (node: IBduiNode): string | Component => {
  switch (node.type) {
    case NodeTypes.Text: {
      return "p";
    }
    // generic это кастомные компоненты реализуемые на клиенте
    case NodeTypes.Generic: {
      if (node.viewType === Generic.CartStepper) {
        return CartStepper;
      }

      if (node.viewType === Generic.FavoriteButton) {
        return FavoriteButton;
      }
    }
      return "div";
    case NodeTypes.AsyncImage: {
      return "img";
    }
    case NodeTypes.Image: {
      return (node.name && images[node.name]) || "img";
    }
    default: {
      return "div";
    }
  }
};

export const parse = (node: IBduiNode, parent: IBduiNode | null, params: IParseParams): IParseResult => {
  const result: IParseResult = {
    component: parseComponent(node),
    attrs: parseAttributes(node, parent),
    children: node.children.map((child: IBduiNode) => parse(child, node, params)),
  };

  if (node.type === NodeTypes.Text) {
    result.text = node.text;
  }

  if (node.viewType === Generic.CartStepper) {
    result.text = "В корзину";
  }

  const clickableMode = node.modifier.find((mode) => mode.type === ModifierTypes.Clickable);
  if (clickableMode && !node.viewType) {
    const dpParams = clickableMode?.event?.action.split("/");
    if (dpParams) {
      const productId = dpParams[dpParams.length - 1];
      result.component = "a";
      result.attrs.href = `${params.productRoute}/${productId}?shopCode=${params.shopCode}&shopType=${params.shopType}`;
    }
  }

  return result;
};
