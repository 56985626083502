import {
  type IUserReviewsObjectFeedback,
  type IUserReviewsOrdersPollDtoResp,
  type IUserReviewsUserPollV2Resp,
  UserReviewsPollScreen,
} from "../typings/api/userReviews";
import { ecomUrls } from "./config";

export default () => {
  const { $transport } = useNuxtApp();

  const getPollById = (id: string, meta: string = "") =>
    $transport<IUserReviewsUserPollV2Resp>(ecomUrls.userReviews.getPollById(id), {
      permissions: {
        jwt: true,
      },
      query: { meta },
    });

  const sendFeedback = (body: IUserReviewsObjectFeedback) =>
    $transport<string>(ecomUrls.userReviews.feedback, {
      method: "POST",
      permissions: {
        jwt: true,
      },
      body,
    });

  const getOrdersPoll = (orderId: string[], screen?: UserReviewsPollScreen) =>
    $transport<IUserReviewsOrdersPollDtoResp>(ecomUrls.userReviews.ordersPoll, {
      permissions: {
        jwt: true,
      },
      query: { orderId, screen },
    });

  return {
    getPollById,
    sendFeedback,
    getOrdersPoll,
  };
};
