import type { IModifier, IOffset } from "./types";
import { ModifierTypes, SizeType } from "./types";

export const argbToRgba = (argb: string): string => {
  const a = Number.parseInt(argb.slice(0, 2), 16) / 255;
  const r = Number.parseInt(argb.slice(2, 4), 16);
  const g = Number.parseInt(argb.slice(4, 6), 16);
  const b = Number.parseInt(argb.slice(6, 8), 16);

  return `rgba(${r}, ${g}, ${b}, ${a.toFixed(2)})`;
};

export const normalizePx = (integer: number): string => `${integer}px`;

export const convertComposalSize = (size: number): string => {
  switch (size) {
    case -1: {
      return "auto";
    }
    case -2: {
      return "minmax(0, 1fr)";
    }
    default: {
      return "";
    }
  }
};

export const normalizeStyles = (styles: Record<string, string | number>): Record<string, string | number> => {
  const result = { ...styles };
  if (result.fontSize) {
    result.fontSize = normalizePx(result.fontSize as number);
  }
  if (result.lineHeight) {
    result.lineHeight = normalizePx(result.lineHeight as number);
  }
  if (result.letterSpacing) {
    result.letterSpacing = normalizePx(result.letterSpacing as number);
  }

  return result;
};

export const computeSize = (modifier: IModifier[], size: number, side: SizeType): string => {
  if (size < 0) {
    return convertComposalSize(size);
  }

  const module_ = modifier.find((m: IModifier) => m.type === ModifierTypes.Padding) as IOffset;
  if (!module_) {
    return normalizePx(size);
  }

  return side === SizeType.Height
    ? normalizePx(size + module_.top + module_.bottom)
    : normalizePx(size + module_.start + module_.end);
};

export const rgbaToArgb = (rgba: string): string => {
  const match = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),\s*(\d*\.?\d+)\)/);
  if (!match) {
    throw new Error(`Неправильный формат цвета. Ожидается: rgba, получен: ${rgba}`);
  }

  const [_, r, g, b, a] = match;
  const alpha = Math.round(Number.parseFloat(a) * 255);
  const toHex = (value: number): string => value.toString(16).padStart(2, "0");

  return `${toHex(alpha)}${toHex(Number(r))}${toHex(Number(g))}${toHex(Number(b))}`.toUpperCase();
};
