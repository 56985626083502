// TODO: в перспективе весь сервис переедет в отдельный пакет
import { parse } from "./parser";
import type { IBduiNode, IParseResult, IParseParams } from "./types";

// Точка входа в парсер
export const parseBduiNode = (layout: IBduiNode, params: IParseParams): IParseResult => {
  return parse(layout, null, params);
};

export * from "./utils";
export * from "./types";
export * from "./maps";
