<script setup lang="ts">
import { vIntersectionObserver } from "@vueuse/components";
import { CatalogType, type ICategoryPreview } from "~/typings/api/sections";

const { send } = useAnalytics();
const { requestCategoryPreviews } = sectionsApi();
const { status, data } = await requestCategoryPreviews();

const categories = computed<ICategoryPreview[]>(() => {
  if (!data.value) {
    return [];
  }

  return data.value.map((item) => {
    const { name, publicImageURL } = item;
    const categoryUrl =
      item.categoryType === CatalogType.Promo ? Routes.PromoCatalog : Routes.Catalog;

    return {
      name,
      publicImageURL,
      url: `${categoryUrl}/${item.categoryId || ""}`,
    };
  });
});

const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) =>
  isIntersecting && send("Main:Catalog:View");
const onVisibilityItem = (
  [{ isIntersecting }]: IntersectionObserverEntry[],
  item: ICategoryPreview,
) => isIntersecting && send("Main:Catalog:Item:View", collectMainCatalogPayload(item));
const onClickItem = (item: ICategoryPreview) =>
  send("Main:Catalog:Item:Click", collectMainCatalogPayload(item));
</script>

<template>
  <SectionsCategoryPreviewsSkeleton v-if="status === 'pending'" />
  <section
    v-else-if="categories.length"
    v-intersection-observer="onVisibility"
    class="category-previews"
  >
    <VWrapperLocal>
      <ul class="category-previews__list" data-test-id="previews-list">
        <li
          v-for="(category, index) in categories"
          :key="index"
          v-intersection-observer="(arg) => onVisibilityItem(arg, category)"
          class="category-previews__list-item"
          @click="onClickItem(category)"
        >
          <NuxtLink
            :to="category.url"
            :title="category.name"
            class="category-previews__link pl-hover-base"
          >
            <AppImage
              :src="category.publicImageURL"
              alt=""
              class="category-previews__image"
              loading="lazy"
            />
            <span class="category-previews__text" lang="ru">{{ category.name }}</span>
          </NuxtLink>
        </li>
      </ul>
    </VWrapperLocal>
  </section>
</template>

<style scoped lang="postcss">
.category-previews {
  padding-top: var(--pl-unit-x5);

  @media (--pl-viewport-from-ml) {
    padding-top: var(--pl-unit-x8);
  }

  @media (--pl-viewport-from-l) {
    padding-top: 64px;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (--pl-viewport-from-l) {
      justify-content: center;
    }
  }

  &__list-item {
    padding: 0 3px;
    margin-top: var(--pl-unit-x4);
    flex-basis: 25%;

    @media (--pl-viewport-from-ml) {
      margin: 0;
      flex-shrink: 0;
      flex-basis: calc(100% / 8);
    }

    @media (--pl-viewport-from-l) {
      flex-basis: 112px;
    }

    @media (--pl-viewport-from-xl) {
      flex-basis: 140px;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    font: var(--pl-font-caption-large-accent-low);
    color: inherit;

    @media (--pl-viewport-from-ml) {
      font: var(--pl-font-body-small-accent-low);
    }

    @media (--pl-viewport-from-xl) {
      font: var(--pl-font-body-large-accent-low);
    }
  }

  &__image,
  &:deep(.category-previews__image) {
    width: 56px;
    height: 56px;
    margin-bottom: var(--pl-unit-x1);

    @media (--pl-viewport-from-xl) {
      width: 72px;
      height: 72px;
      margin-bottom: 8px;
    }
  }

  &__text {
    overflow-wrap: anywhere;
    -webkit-hyphenate-limit-before: 4;
    -webkit-hyphenate-limit-after: 3;
    -webkit-hyphenate-limit-chars: 6 4 3;
    -webkit-hyphenate-limit-lines: 2;
    -webkit-hyphenate-limit-last: always;
    -moz-hyphenate-limit-chars: 6 4 3;
    -moz-hyphenate-limit-lines: 2;
    -moz-hyphenate-limit-last: always;
    -ms-hyphenate-limit-chars: 6 4 3;
    -ms-hyphenate-limit-lines: 2;
    -ms-hyphenate-limit-last: always;
    hyphens: auto;
    hyphenate-limit-chars: 6 4 3;
    hyphenate-limit-lines: 2;
    hyphenate-limit-last: always;
  }
}
</style>
