<script setup lang="ts">
import { vElementVisibility } from "@vueuse/components";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_LABEL,
  DEFAULT_TITLE,
  DEFAULT_URL,
} from "~/constants/magazine";

const { send } = useAnalytics();
const { requestMagazine } = sectionsApi();
const { pending, data: magazine } = await requestMagazine();

const label = computed(() => (magazine.value?.label || DEFAULT_LABEL).toLowerCase());
const title = computed(() => magazine.value?.title || DEFAULT_TITLE);
const description = computed(() => magazine.value?.description || DEFAULT_DESCRIPTION);
const url = computed(() => magazine.value?.url || DEFAULT_URL);
const onVisibility = (v: boolean) => v && send("Main:MagnitJournal:View");
const onClick = () => send("Main:MagnitJournal:Click");
</script>

<template>
  <SectionsMagazineSkeleton v-if="pending" />
  <section v-else-if="magazine" v-element-visibility="onVisibility" class="magazine">
    <VWrapperLocal class="wrapper">
      <div class="inner">
        <div class="picture">
          <AppImage
            :src="magazine?.publicImageURL"
            :alt="title"
            class="image image-front"
            loading="lazy"
          />
          <AppImage
            :src="magazine?.publicImageURL"
            :alt="title"
            class="image image-back"
            loading="lazy"
          />
          <div class="label">
            {{ label }}
          </div>
        </div>
        <div class="text">
          <VText tag="div" :font="{ xs: 'headline-small', xl: 'headline-medium' }" class="title">
            {{ title }}
          </VText>
          <VText tag="div" font="body-large-accent" class="description">
            {{ description }}
          </VText>
        </div>
        <a :href="url" target="_blank" class="link">
          <VButton tag="span" size="l" class="button" @click="onClick"> Читать </VButton>
        </a>
      </div>
    </VWrapperLocal>
  </section>
</template>

<style scoped lang="postcss">
.magazine {
  padding-top: 48px;

  /* min-height прописаны чтобы уменьшить прыжки страницы пока картинки рендерит */
  min-height: 508px;

  @media (--pl-viewport-from-s) {
    min-height: 455px;
  }

  @media (--pl-viewport-from-m) {
    padding-top: 56px;
  }

  @media (--pl-viewport-from-l) {
    padding-top: 64px;
    min-height: 254px;
  }

  @media (--pl-viewport-from-xl) {
    min-height: 288px;
  }
}

.wrapper {
  @media (--pl-viewport-from-l) {
    padding-top: var(--pl-unit-x8);
    overflow: hidden;
  }

  @media (--pl-viewport-from-xl) {
    padding-top: 67px;
  }
}

.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--pl-unit-x4);
  padding: 0 var(--pl-unit-x4) var(--pl-unit-x4);

  @media (--pl-viewport-from-m) {
    padding: 0 var(--pl-unit-x8) var(--pl-unit-x8);
  }

  @media (--pl-viewport-from-l) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0;
    gap: var(--pl-unit-x6);
  }

  @media (--pl-viewport-from-xl) {
    padding: 0 90px 0 34px;
  }

  &::before {
    content: "";
    display: block;
    background-color: var(--core-ginger-pale);
    border-radius: var(--pl-badge-radius-m);
    position: absolute;
    inset: 116px 0 0;
    z-index: var(--pl-z-index-negative);

    @media (--pl-viewport-from-s) {
      top: 46px;
    }

    @media (--pl-viewport-from-l) {
      border-radius: var(--pl-badge-radius-l);
      top: 0;
    }
  }
}

.picture {
  align-self: center;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: var(--pl-z-index-level0);
  aspect-ratio: 1 / 1.33;
  padding-bottom: var(--pl-unit-x3);

  @media (--pl-viewport-from-l) {
    align-self: auto;
    padding-bottom: 0;
    margin: -36px 64px -36px var(--pl-unit-x4);
  }

  @media (--pl-viewport-from-xl) {
    margin: -67px 64px -42px var(--pl-unit-x9);
  }
}

.image-front,
.magazine:deep(.image-front) {
  z-index: var(--pl-z-index-level1);
  width: 134px;

  @media (--pl-viewport-from-l) {
    width: 167px;
  }

  @media (--pl-viewport-from-xl) {
    width: 197px;
  }
}

.image-back,
.magazine:deep(.image-back) {
  position: absolute;
  width: 123px;
  left: -16px;
  top: var(--pl-unit-x4);
  transform: rotate(-6deg);

  @media (--pl-viewport-from-l) {
    width: 153px;
  }

  @media (--pl-viewport-from-xl) {
    width: 181px;
  }
}

.label {
  position: absolute;
  z-index: var(--pl-z-index-level2);
  bottom: 10px;
  left: 100%;
  width: min-content;
  padding: var(--pl-unit-x7);
  background: linear-gradient(
    333deg,
    var(--pl-button-background-primary-default) 50%,
    var(--pl-button-background-primary-disabled)
  );
  color: white;
  border-radius: 100px;
  text-align: center;
  font-family: MagnitBox, MagnitSans, sans-serif;
  font-size: 22px;
  font-weight: 800;
  transform: rotate(-10deg) translate(-70px, -8px);
  line-height: 0.9;

  @media (--pl-viewport-from-l) {
    bottom: 30px;
  }

  @media (--pl-viewport-from-xl) {
    bottom: 38px;
  }
}

.text {
  z-index: var(--pl-z-index-level0);
  padding: 0 var(--pl-unit-x4);
  text-align: center;

  @media (--pl-viewport-from-l) {
    max-width: 380px;
    text-align: left;
    padding: var(--pl-unit-x8) 0;
  }
}

.title,
.description {
  word-wrap: break-word;
}

.description {
  margin-top: var(--pl-unit-x2);

  @media (--pl-viewport-from-l) {
    margin-top: var(--pl-unit-x3);
  }
}

.button {
  width: 100%;

  @media (--pl-viewport-from-l) {
    width: 160px;
  }
}

.link {
  display: block;
}
</style>
