<script lang="ts" setup>
const { partnersSectionEnabled, bduiSectionEnabled } = usePublicConfig();

const { isEcom } = usePublicConfig();
const storesStore = useStoresStore();
const keywords = computed(
  () =>
    `Магнит, Косметик, Аптека, Семейный, Экстра, Моя цена, официальный сайт, цены, акции и скидки, каталог товаров всех магазинов, в городе ${storesStore.currentStoreCity}, продукты`,
);

definePageMeta({ name: ROUTE_INDEX });

useSeoMeta({
  keywords,
});

const { send } = useAnalytics();

onMounted(() => {
  send("Main:View");
});
</script>

<template>
  <div>
    <EcomOrderStatus v-if="isEcom" />
    <SectionsMainSlider />
    <SectionsCategoryPreviews />
    <SectionsSpecialOffers />
    <SectionsPopularSlider />
    <SectionsTicker />
    <SectionsWeekProducts />
    <SectionsNewProducts />
    <SectionsPharmacy />
    <SectionsEverGreenBanners />
    <BduiShelf v-if="bduiSectionEnabled" />
    <SectionsOwnBrands />
    <!--    <SectionsRaffles /> -->
    <SectionsPartnersOffers v-if="partnersSectionEnabled" />
    <SectionsMagazine />
    <SectionsMobileAppBanner />
  </div>
</template>
