<script lang="ts" setup>
import type { IBduiNodeProps } from "./Node.types.js";
import { imagesPath } from "~/layers/bdui/services/parser";

const props = defineProps<IBduiNodeProps>();

const loadError = ref(false);

const onLoadError = (): void => {
  if (props.node.attrs.altSrc) {
    loadError.value = true;
  }
};

const attrs = computed(() => {
  const attributes = props.node.attrs;
  if (loadError.value && attributes.altSrc && imagesPath[attributes.altSrc]) {
    return {
      ...attributes,
      src: imagesPath[attributes.altSrc],
    };
  }

  return props.node.attrs;
});
</script>

<template>
  <component :is="props.node.component" v-bind="attrs" @error="onLoadError">
    <template v-if="props.node.text">
      {{ node.text }}
    </template>
    <template v-if="props.node.children.length">
      <Node v-for="(child, idx) in props.node.children" :key="idx" :node="child" />
    </template>
  </component>
</template>
