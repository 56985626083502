import { HEADERS_SCEMA_VERSION } from "~/constants/headers.js";
import { SHELF_ID_FOR_MAIN_PAGE, ACTUAL_SCEMA_VERSION } from "~/constants/bdui.js";
import type { ICatalogBduiGetShelfV2Response } from "~/typings/api/bdui.js";

export default () => {
  const storesStore = useStoresStore();

  const requestBduiShelf = () => {
    const { $transport } = useNuxtApp();

    return $transport<ICatalogBduiGetShelfV2Response>(urls.bdui.getShelf, {
      query: {
        "store-code": storesStore.requestShopCode,
        "store-type": String(storesStore.requestShopType),
        "catalog-type": String(API_CATALOG_TYPE),
        "shelf-id": SHELF_ID_FOR_MAIN_PAGE,
      },
      headers: {
        [HEADERS_SCEMA_VERSION]: String(ACTUAL_SCEMA_VERSION),
      },
    });
  };

  return {
    requestBduiShelf,
  };
};
