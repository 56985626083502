import type { images, contentScaleMap } from "./maps";

export enum SizeType {
  Height = "height",
  Width = "width",
}

export enum Generic {
  CartStepper = "cartStepper",
  FavoriteButton = "favoriteButton",
}

export enum NodeTypes {
  AsyncImage = "asyncImage",
  Text = "text",
  Generic = "generic",
  Image = "image",
  Column = "column",
  Row = "row",
  Box = "box",
  Spacer = "spacer",
}

export enum ModifierTypes {
  Background = "background",
  BackgroundGradient = "backgroundGradient",
  Size = "size",
  Padding = "padding",
  Clip = "clip",
  Align = "align",
  Scrollable = "scrollable",
  AspectRatio = "aspectRatio",
  FillAvailableHeight = "fillAvailableHeight",
  FillAvailableWidth = "fillAvailableWidth",
  Clickable = "clickable",
}

export enum Alignments {
  Center = "Center",
  TopEnd = "TopEnd",
  Start = "Start",
  BottomStart = "BottomStart",
  TopStart = "TopStart",
}

export enum Orientations {
  Horizontal = "horizontal",
}

export enum ShapeTypes {
  RoundedCorners = "roundedCorners",
}

export interface IShapePosition {
  topStart: number;
  topEnd: number;
  bottomEnd: number;
  bottomStart: number;
}

export interface IShape extends Partial<IShapePosition> {
  type: string;
}

export interface IOffset {
  start: number;
  end: number;
  top: number;
  bottom: number;
}

export interface IEvent {
  action: string;
}

export interface IModifier extends Partial<IOffset> {
  type: string;
  color?: string;
  colors?: string[];
  offsets?: number[];
  shape?: IShape;
  height?: number;
  width?: number;
  alignment?: Alignments;
  orientation?: string;
  event?: IEvent;
}

export interface IBduiNode {
  type: string;
  alignment?: string;
  children: IBduiNode[];
  modifier: IModifier[];
  maxLines?: number;
  color?: string;
  style?: Record<string, string | number>;
  contentScale?: keyof typeof contentScaleMap;
  text?: string;
  name?: keyof typeof images;
  url?: string;
  placeholderName?: string;
  data?: Record<string, any>;
  viewType?: Generic;
}

export interface IParseResult {
  component: string | Component;
  attrs: Record<string, any>;
  children: IParseResult[];
  text?: string;
}

export interface IParseParams {
  productRoute?: string;
  shopCode?: string;
  shopType?: string;
}

export interface TEvents {
  [key: string]: (...args: any[]) => void;
}
export type TAttributes = Record<string, Record<string, string | number> | string | TEvents>;
