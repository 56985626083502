<script setup lang="ts">
import VText from "@magnit/core/src/components/VText/VText.vue";
import VYaSlot from "@magnit/core/src/containers/VYaSlot/VYaSlot.vue";
import VWrapper from "@magnit/core/src/components/VWrapper/VWrapper.vue";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import type { IYaSlotConfig } from "@magnit/core/src/containers/VYaSlot/VYaSlot.types";
import VAppLink from "@magnit/core/src/components/VAppLink/VAppLink.vue";
import { IYaSlotApiStatus, useSlotsStore } from "~/stores/useSlotsStore";

const { adfoxOwnerId } = usePublicConfig();
const slotApi = useSlotsStore();
const slides: IYaSlotConfig[] = [
  {
    id: "adfox_17138171741543746",
    p1: "dbzsu",
    p2: "htnq",
  },
  {
    id: "adfox_17138172630537746",
    p1: "dbzsv",
    p2: "htnq",
  },
  {
    id: "adfox_17138172773577746",
    p1: "dbzsw",
    p2: "htnq",
  },
  {
    id: "adfox_17138172902027746",
    p1: "dbzsx",
    p2: "htnq",
  },
];

const stubedSlides = ref<string[]>([]);
const onSlideStab = (id: string) => {
  stubedSlides.value.push(id);
};
const displaySlides = computed(() => slides?.filter((i) => !stubedSlides.value.includes(i.id)));
</script>

<template>
  <section class="pharmacy">
    <VWrapperLocal class="pharmacy__title">
      <VText :font="{ xs: 'headline-small', l: 'headline-large' }">
        Магнит Аптека
      </VText>
    </VWrapperLocal>
    <VWrapper :indent="{ ml: '32px' }">
      <AppSlider
        :breakpoints="{
          xs: {
            slidesPerView: 'auto',
            spaceBetween: 8,
            slidesOffsetAfter: 12,
            slidesOffsetBefore: 12,
            navigation: false,
          },
          s: {
            slidesPerView: 'auto',
            spaceBetween: 12,
            slidesOffsetAfter: 20,
            slidesOffsetBefore: 20,
          },
          m: {
            slidesPerView: 'auto',
            spaceBetween: 20,
            slidesOffsetAfter: 32,
            slidesOffsetBefore: 32,
          },
          ml: {
            slidesPerView: 4,
            spaceBetween: 20,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
          },
        }"
      >
        <AppSliderSlide v-for="item in displaySlides" :key="item.id" :size="{ xs: 6, m: 4 }">
          <VYaSlot
            :api-ready="slotApi.slotStatus === IYaSlotApiStatus.SUCCESS"
            :api-error="slotApi.slotStatus === IYaSlotApiStatus.ERROR"
            :owner-id="adfoxOwnerId"
            :config="item"
            with-hover
            @stub="onSlideStab(item.id)"
          />
        </AppSliderSlide>
      </AppSlider>
    </VWrapper>
    <VWrapperLocal>
      <div class="pharmacy__info">
        <div class="pharmacy__info-img-container">
          <img src="/np/images/product/apteka-cart.webp" alt="" class="pharmacy__info-img">
        </div>
        <div class="pharmacy__info-text">
          <VText
            class="pharmacy__info-title"
            :font="{ xs: 'headline-small', l: 'headline-medium' }"
          >
            Заберите из любой удобной Магнит Аптеки
          </VText>
          <VText
            class="pharmacy__info-descr"
            :font="{ xs: 'body-large-accent-low', l: 'body-large-accent' }"
          >
            Широкий ассортимент, доступные цены и выгодные покупки с картой Магнит
          </VText>
        </div>
        <div class="pharmacy__info-btn">
          <VAppLink to="https://apteka.magnit.ru/">
            <VButton full-width theme="invert">
              Перейти в Аптеку
            </VButton>
          </VAppLink>
        </div>
      </div>
    </VWrapperLocal>
  </section>
</template>

<style scoped lang="postcss">
.pharmacy {
  padding-top: 38px;

  /* min-height прописаны чтобы уменьшить прыжки страницы пока картинки рендерит */

  /* баннеры adfox решили здесь не включать в эту минимальную высоту, т.к. оказалось адфокс вообще не гарантирован */
  min-height: 518px;

  @media (--pl-viewport-from-ml) {
    min-height: 312px;
  }

  @media (--pl-viewport-from-l) {
    padding-top: 50px;
    min-height: 272px;
  }

  &__title {
    padding-bottom: 14px;

    @media (--pl-viewport-from-l) {
      padding-bottom: 18px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-items: center;
    text-align: center;
    margin-top: var(--pl-unit-x3);
    background-color: #9de0e6;
    border-radius: var(--pl-unit-x4);
    padding: var(--pl-unit-x4);
    overflow: hidden;

    @media (--pl-viewport-from-s) {
      margin-top: var(--pl-unit-x4);
    }

    @media (--pl-viewport-from-m) {
      margin-top: var(--pl-unit-x5);
    }

    @media (--pl-viewport-from-ml) {
      display: grid;
      height: 208px;
      border-radius: var(--pl-unit-x5);
      row-gap: var(--pl-unit-x1);
      place-items: center start;
      text-align: start;
      grid-template:
        "text img" auto
        "btn img" auto;
    }

    @media (--pl-viewport-from-l) {
      row-gap: unset;
      height: 140px;
      margin-top: var(--pl-unit-x6);
      padding: 0 var(--pl-unit-x10);
      border-radius: var(--pl-unit-x7);
      grid-template: "text img btn" auto;
    }

    @media (--pl-viewport-from-xl) {
      border-radius: var(--pl-unit-x8);
    }

    &-text {
      display: flex;
      flex-direction: column;
      row-gap: var(--pl-unit-x1);
      grid-area: text;

      @media (--pl-viewport-from-s) {
        row-gap: var(--pl-unit-x4);
      }

      @media (--pl-viewport-from-ml) {
        row-gap: var(--pl-unit-x1);
      }
    }

    &-img-container {
      overflow: hidden;

      @media (--pl-viewport-from-xl) {
        grid-area: img;
        margin-left: var(--pl-unit-x4);
      }

      @media (--pl-viewport-from-ml) {
        padding-right: 95px;
      }

      @media (--pl-viewport-from-l) {
        padding-right: unset;
      }
    }

    &-img {
      position: relative;
      width: 237px;
      height: 188px;

      @media (--pl-viewport-from-ml) {
        width: 379px;
        height: 301px;
        top: 69px;
      }

      @media (--pl-viewport-from-l) {
        top: 27px;
        width: 269px;
        height: 214px;
      }

      @media (--pl-viewport-from-xl) {
        top: 36px;
        left: 37px;
        width: 299px;
        height: 238px;
      }
    }

    &-btn {
      width: 100%;
      margin-top: var(--pl-unit-x4);

      @media (--pl-viewport-from-s) {
        width: unset;
      }

      @media (--pl-viewport-from-ml) {
        margin-top: var(--pl-unit-x2);
      }

      @media (--pl-viewport-from-l) {
        margin-top: unset;
      }
    }
  }

  :deep(.slider-slide) {
    overflow: hidden;
    border-radius: var(--pl-unit-x4);

    @media (--pl-viewport-from-m) {
      border-radius: var(--pl-unit-x5);
    }

    @media (--pl-viewport-from-l) {
      border-radius: var(--pl-unit-x6);
    }

    @media (--pl-viewport-from-xl) {
      border-radius: var(--pl-unit-x8);
    }
  }
}
</style>
