import { argbToRgba, normalizePx, computeSize, convertComposalSize, normalizeStyles } from "./utils";
import type { IBduiNode, IModifier, IShapePosition, IOffset, IShape } from "./types";
import { Alignments, ModifierTypes, NodeTypes, ShapeTypes, SizeType, Orientations } from "./types";
import { contentScaleMap, defaultStyle } from "./maps";

// TODO: обработать все позиции
const parseAlign = (alignment: Alignments): Record<string, string> => {
  switch (alignment) {
    case Alignments.Center: {
      return {
        justifySelf: "center",
        alignSelf: "center",
      };
    }
    case Alignments.TopEnd: {
      return {
        justifySelf: "end",
      };
    }
    case Alignments.BottomStart: {
      return {
        justifySelf: "start",
        alignSelf: "end",
      };
    }
    case Alignments.TopStart: {
      return {
        justifySelf: "start",
        alignSelf: "start",
      };
    }
    default: {
      return {};
    }
  }
};

const parseShape = (shape: IShape): Record<string, string> => {
  const style: Record<string, string> = {};

  if (shape.type === ShapeTypes.RoundedCorners) {
    const sh = shape as IShapePosition;
    style.borderRadius = `${normalizePx(sh.topStart)} ${normalizePx(sh.topEnd)} ${normalizePx(
      sh.bottomEnd,
    )} ${normalizePx(sh.bottomStart)}`;
  }

  return style;
};

/*
  Получение стилий из модифаеров ноды
*/
const parseStyleByModifier = (modifier: IModifier[]): Record<string, string> =>
  modifier.reduce(
    (style: Record<string, string>, mode: IModifier): Record<string, string> => {
      switch (mode.type) {
        case ModifierTypes.Background: {
          const color = mode.color ? argbToRgba(mode.color) : "";
          style.backgroundColor = color;
          if (mode.shape) {
            style = {
              ...style,
              ...parseShape(mode.shape),
            };
          }
          break;
        }
        case ModifierTypes.BackgroundGradient: {
          // TODO: обработать angle
          const defaultAngle = "90deg";

          const gradientValue =
            mode.offsets?.length === mode.colors?.length
              ? mode.colors!.reduce((value, color, index) => {
                // Флоаты переводим в проценты
                  return `${value}, ${argbToRgba(color)} ${mode.offsets![index] * 100}%`;
                }, defaultAngle)
              : mode.colors!.map((c) => argbToRgba(c)).join(", ");

          style.backgroundImage = `linear-gradient(${gradientValue})`;
          if (mode.shape) {
            style = {
              ...style,
              ...parseShape(mode.shape),
            };
          }
          break;
        }
        case ModifierTypes.Size: {
          if (mode.height !== undefined) {
            style.height = computeSize(modifier, mode.height, SizeType.Height);
          }

          if (mode.width !== undefined) {
            style.width = computeSize(modifier, mode.width, SizeType.Width);
          }

          break;
        }
        case ModifierTypes.Padding: {
          const offset = mode as IOffset;

          if (offset.start > 0) {
            style.marginLeft = normalizePx(offset.start);
          }
          if (offset.end > 0) {
            style.marginRight = normalizePx(offset.end);
          }
          if (offset.top > 0) {
            style.marginTop = normalizePx(offset.top);
          }
          if (offset.bottom > 0) {
            style.marginBottom = normalizePx(offset.bottom);
          }

          break;
        }
        case ModifierTypes.Clip: {
          if (mode.shape) {
            style = {
              ...style,
              ...parseShape(mode.shape),
            };
          }
          break;
        }
        case ModifierTypes.Align: {
          const align = mode?.alignment ? { ...parseAlign(mode.alignment) } : {};

          style = {
            ...style,
            ...align,
          };
          break;
        }
        case ModifierTypes.Scrollable: {
          if (mode?.orientation?.toLowerCase() === Orientations.Horizontal) {
            style.maxWidth = "100%";
            style.overflowX = "scroll";
          }
          break;
        }
        case ModifierTypes.AspectRatio: {
          if (mode.width !== undefined && mode.height !== undefined) {
            // TODO: Разобраться как это работает. Бывает одновременно и размер и aspectRatio
            const sizeModifier = modifier.find((m) => m.type === ModifierTypes.Size);
            if (!sizeModifier) {
              style.height = computeSize(modifier, mode.height, SizeType.Height);
              style.width = computeSize(modifier, mode.width, SizeType.Width);
            }
          }

          break;
        }
        case ModifierTypes.FillAvailableWidth:
        case ModifierTypes.FillAvailableHeight: {
          style.alignSelf = "stretch";
          style.justifySelf = "stretch";
          break;
        }
        case ModifierTypes.Clickable: {
          style.cursor = "pointer";
          break;
        }
        default: {
          // Заглушка с выводом необработанных типов
          // console.log(mode.type)
        }
      }

      return style;
    },
    {} as Record<string, string>,
  );

/*
  Получение стилий по типу ноды
*/
const parseStyleByNodeType = (node: IBduiNode): Record<string, string> => {
  let style: Record<string, string> = {};

  switch (node.type) {
    case NodeTypes.Column: {
      style = {
        display: "grid",
        height: "fit-content",
        gridTemplateColumns: "1fr",
        overflow: "hidden",
        gridTemplateRows: node.children
          .reduce((value: string, child: IBduiNode) => {
            const sizeMode = child.modifier.find((mode: IModifier) => mode.type === ModifierTypes.Size);
            if (sizeMode && sizeMode.height !== undefined) {
              value += ` ${sizeMode.height < 0 ? convertComposalSize(sizeMode.height) : normalizePx(sizeMode.height)}`;
            } else if (child.type === NodeTypes.Spacer) {
              value += " 1fr";
            } else {
              value += " auto";
            }

            return value;
          }, "")
          .trim(),
      };

      break;
    }
    case NodeTypes.Row: {
      // Костыль для правильной заливки фона через указания ширины fit-content
      let hasSpacer = false;

      style = {
        display: "grid",
        width: "fit-content",
        height: "inherit",
        gridTemplateRows: "1fr",
      };

      // Хотфикс скрола в сафари (не работает с fit-content)
      if (node.children.length === 1 && node.children[0].type === NodeTypes.Row) {
        style.width = "100%";
        style.gridTemplateColumns = "1fr";
      } else {
        style.gridTemplateColumns = node.children
          .reduce((value: string, child: IBduiNode) => {
            const sizeMode = child.modifier.find((mode: IModifier) => mode.type === ModifierTypes.Size);
            const paddingMode = child.modifier.find((mode: IModifier) => mode.type === ModifierTypes.Padding);

            if (sizeMode && sizeMode.width !== undefined) {
              const width = paddingMode?.start ? paddingMode?.start + sizeMode.width : sizeMode.width;
              value += ` ${sizeMode.width < 0 ? convertComposalSize(sizeMode.width) : normalizePx(width)}`;
            } else if (child.type === NodeTypes.Spacer) {
              hasSpacer = true;
              value += " 1fr";
            } else {
              value += " auto";
            }

            return value;
          }, "")
          .trim();
      }

      const delta = node.modifier.reduce((result: number, mode: IModifier) => {
        if (mode.type === ModifierTypes.Padding) {
          result += (mode.top || 0) + (mode.bottom || 0);
        }

        return result;
      }, 0);

      if (delta > 0) {
        style.height = `calc(100% - ${delta}px)`;
      }

      if (hasSpacer) {
        style.width = "auto";
      }

      break;
    }
    case NodeTypes.AsyncImage: {
      const contentScale =
        node.contentScale === undefined ? {} : { ...contentScaleMap[node.contentScale.toLowerCase()] };

      style = {
        ...style,
        ...contentScale,
        width: "100%",
        height: "100%",
      };
      break;
    }
    case NodeTypes.Image: {
      style = {
        alignSelf: "center",
        justifySelf: "center",
        ...style,
      };
      break;
    }
    case NodeTypes.Box: {
      style = {
        ...style,
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr",
        overflow: "hidden",
        width: "fit-content",
        height: "inherit",
      };
      break;
    }
    case NodeTypes.Text: {
      const defaultMaxLines = 2;
      const maxLines = node.maxLines || defaultMaxLines;
      const color = node.color ? argbToRgba(node.color) : "";

      // Корректно обрезаем однострочный текст
      const wordBreak = node.maxLines === 1 ? "break-all" : "normal";

      style = {
        ...style,
        "color": color,
        "display": "-webkit-box",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": maxLines.toString(),
        "overflow": "hidden",
        "word-break": wordBreak,
      };

      break;
    }
    default:
  }

  if (node.alignment) {
    switch (node.alignment.toLowerCase()) {
      // TODO: обработать остальное позиционирование
      case Alignments.Center: {
        style.justifyContent = "center";
        style.alignItems = "center";
        break;
      }
      default:
    }
  }

  return style;
};

/*
  Получение стилий по ноде
*/
export const parseStyle = (node: IBduiNode): Record<string, string | number> => {
  let style: Record<string, string | number> = {
    ...defaultStyle,
    ...parseStyleByNodeType(node),
    ...parseStyleByModifier(node.modifier),
  };

  if (node.style) {
    // Размеры передаются в виде флоата
    style = { ...style, ...normalizeStyles(node.style) };
  }

  return style;
};
