import { parseStyle } from "./style";
import { type IBduiNode, NodeTypes, type TAttributes } from "./types";
import { Generic } from "./types";

/*
  Получение всех атрибутов ноды
*/
export const parseAttributes = (node: IBduiNode, parent: IBduiNode | null): TAttributes => {
  const attributes: TAttributes = {
    style: parseStyle(node),
    class: node.type,
  };

  switch (node.type) {
    case NodeTypes.AsyncImage: {
      if (node.url) attributes.src = node.url;
      if (node.placeholderName) attributes.altSrc = node.placeholderName; // План б
      break;
    }
    default:
  }

  if (node.viewType === Generic.FavoriteButton && node.data !== undefined) {
    attributes.isFavorite = node.data.isFavorite;
  }

  // Компонента бокс работает как стэк. Накладываем элементы друг на друга через грид
  if (parent && parent.type === NodeTypes.Box && typeof attributes.style === "object") {
    attributes.style = {
      ...attributes.style,
      order: parent.children.indexOf(node),
      gridColumn: 1,
      gridRow: 1,
    };
  }

  return attributes;
};
