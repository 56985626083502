export const HEADERS_COOKIE = "cookie";
export const HEADERS_FINGERPRINT = "X-Device-ID";
export const HEADERS_CLIENT_NAME = "X-Client-Name";
export const HEADERS_PLATFORM_VERSION = "X-Platform-Version";
export const HEADERS_APP_VERSION = "X-App-Version";
export const HEADERS_DEVISE_PLATFORM = "X-Device-Platform";
export const HEADERS_DEVICE_TAG = "X-Device-Tag";
export const HEADERS_USER_AGENT = "User-Agent";
export const HEADERS_CONTENT_TYPE = "Content-Type";
export const HEADERS_NEW_MAGNIT = "X-New-Magnit";
export const HEADERS_CATALOG_TYPE = "X-Catalog-Type";
export const HEADERS_SHOP_TYPE = "X-Shop-Type";
export const HEADERS_SCEMA_VERSION = "X-Schema-version";
