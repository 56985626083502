<script setup lang="ts">
import { useIntervalFn } from "@vueuse/core";
import VOrderStatusItem from "@magnit/unit-ecom/src/components/VOrderStatus/VOrderStatusItem.vue";
import VWrapper from "@magnit/core/src/components/VWrapper/VWrapper.vue";
import useEcomModals from "@magnit/unit-ecom/src/composables/useModals";
import VOrderReviewModal from "@magnit/unit-ecom/src/components/Modals/VOrderReviewModal.vue";
import type { IUserReviewsObjectFeedback } from "@magnit/layer-api/typings";
import type { IDtoV2OrderItem } from "~/layers/ecom/typings/api/postcheckout";
import { EcomRoutes } from "~/layers/ecom/utils/routes";
import type {
  IUserReviewsOrdersPollDtoResp,
  IUserReviewsUserPollV2Resp,
} from "~/layers/ecom/typings/api/userReviews";

const UPDATE_ORDERS_TIMEOUT = 3_000;
const LIMIT = 10;

const { requestOrdersActive } = postCheckoutApi();
const { getPollById, sendFeedback, getOrdersPoll } = userReviews();
const { isAuthorized } = useAuth();
const { useReviewModal } = useEcomModals();
const { open: openReviewModal, isOpen: isOpenReviewModal } = useReviewModal();

const currentOrderId2Poll = ref("");
const currentOrderPoll = ref<IUserReviewsUserPollV2Resp | undefined>();
const currentOrderPollLoading = ref(false);
const currentOrderPollError = ref(false);
const currentOrderPollSuccess = ref(false);
const pollInitialRate = ref(0);
const activeOrders = ref<IDtoV2OrderItem[]>([]);
const pollData = ref<IUserReviewsOrdersPollDtoResp | undefined>();
const isSlider = computed(() => activeOrders.value.length > 1);

const fetchData = async () => {
  try {
    const ordersResp = await requestOrdersActive(1, LIMIT);
    activeOrders.value = ordersResp.items;

    const orderIds = ordersResp.items.map(({ orderId }) => orderId);
    if (orderIds.length) {
      pollData.value = await getOrdersPoll(orderIds);
    }
  } catch {
    activeOrders.value = [];
  }
};

const pollAllowed = (orderId: string) => Boolean(getPollByOrderId(orderId));
const getPollByOrderId = (orderId: string) =>
  pollData.value?.orders_polls?.find((v) => v.order_id === orderId);

const { pause, resume } = useIntervalFn(fetchData, UPDATE_ORDERS_TIMEOUT, { immediate: false });

const onFetchPoll = async (rate: number, orderId: string) => {
  currentOrderId2Poll.value = orderId;
  pollInitialRate.value = rate;

  if (!isOpenReviewModal.value) openReviewModal();

  const poll = getPollByOrderId(currentOrderId2Poll.value);
  if (!poll) {
    currentOrderPollError.value = true;
    return;
  }

  currentOrderPollLoading.value = true;
  currentOrderPollSuccess.value = false;
  currentOrderPollError.value = false;

  try {
    currentOrderPoll.value = await getPollById(poll.poll_id, poll.meta);
  } catch {
    currentOrderPollError.value = true;
  } finally {
    currentOrderPollLoading.value = false;
  }
};

const onSendFeedback = async (v: IUserReviewsObjectFeedback) => {
  currentOrderPollLoading.value = true;
  currentOrderPollSuccess.value = false;
  currentOrderPollError.value = false;
  try {
    await sendFeedback(v);
    currentOrderPollSuccess.value = true;
  } catch {
    currentOrderPollError.value = true;
  } finally {
    currentOrderPollLoading.value = false;
  }
};

watch(isAuthorized, (v) => {
  if (v) {
    resume();
  } else {
    pause();
    activeOrders.value = [];
  }
});

watch(activeOrders.value, (v, oldValue) => {
  if (v < oldValue) pause();
});

onMounted(() => {
  if (!isAuthorized.value) return;
  fetchData();
  setTimeout(fetchData, UPDATE_ORDERS_TIMEOUT);
});
</script>

<template>
  <div v-if="activeOrders.length" class="active-orders">
    <VWrapper :indent="{ l: '16px' }">
      <template v-if="isSlider">
        <AppSlider
          slides-per-view="auto"
          class="active-order__slider"
          :breakpoints="{
            xs: {
              slidesPerView: 'auto',
              spaceBetween: 16,
              slidesOffsetAfter: 12,
              slidesOffsetBefore: 12,
            },
            s: {
              slidesPerView: 'auto',
              spaceBetween: 16,
              slidesOffsetAfter: 20,
              slidesOffsetBefore: 20,
            },
            m: {
              slidesPerView: 'auto',
              spaceBetween: 16,
              slidesOffsetAfter: 32,
              slidesOffsetBefore: 32,
            },
            l: {
              slidesPerView: 'auto',
              spaceBetween: 16,
              slidesOffsetAfter: 0,
              slidesOffsetBefore: 0,
            },
            xl: {
              slidesPerView: 'auto',
              spaceBetween: 12,
              slidesOffsetAfter: 0,
              slidesOffsetBefore: 0,
            },
          }"
        >
          <AppSliderSlide v-for="item in activeOrders" :key="item.orderId">
            <NuxtLink :to="`${EcomRoutes.Orders}/${item.orderId}`" class="active-orders__slide">
              <VOrderStatusItem
                short
                :poll-allowed="true || pollAllowed(item.orderId)"
                :order-id="item.orderId"
                :status="item.status"
                :total-price="item.totalPrice"
                :receive-type="item.receiveType"
                :formatted-address="item.address"
                @fetch-poll="(rate: number) => onFetchPoll(rate, item.orderId)"
              />
            </NuxtLink>
          </AppSliderSlide>
        </AppSlider>
      </template>
      <template v-else>
        <NuxtLink
          v-for="item in activeOrders"
          :key="item.orderId"
          :to="`${EcomRoutes.Orders}/${item.orderId}`"
        >
          <VOrderStatusItem
            :poll-allowed="pollAllowed(item.orderId)"
            :order-id="item.orderId"
            :status="item.status"
            :total-price="item.totalPrice"
            :receive-type="item.receiveType"
            :formatted-address="item.address"
            @fetch-poll="(rate: number) => onFetchPoll(rate, item.orderId)"
          />
        </NuxtLink>
      </template>
    </VWrapper>
    <VOrderReviewModal
      :poll-data="currentOrderPoll"
      :order-id="currentOrderId2Poll"
      :poll-initial-rate="pollInitialRate"
      :loading="currentOrderPollLoading"
      :error="currentOrderPollError"
      :success="currentOrderPollSuccess"
      @fetch="onFetchPoll"
      @send="onSendFeedback"
    />
  </div>
</template>

<style scoped lang="postcss">
.active-orders {
  margin-bottom: var(--pl-unit-x4);

  &:deep(.slider) {
    height: 96px;

    @media (--pl-viewport-from-l) {
      padding: 0 var(--pl-unit-x4);
    }
  }

  :deep(.slider-slide) {
    width: auto;
  }
}
</style>
