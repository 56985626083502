import { ecomUrls } from "~/layers/ecom/api/config";
import type {
  IDtoCancelReasonsResponse,
  IDtoV2CartsAvailableToReturn,
  IDtoV2OrderInfoResponse, IDtoV2OrderReorderResponse,
  IDtoV2OrderReturns,
  IDtoV2OrdersListResponse,
  IOrderCourierTrackingResponse,
  IOrderCreateReturnRequest,
  ISuccess,
} from "~/layers/ecom/typings/api/postcheckout";

import { OrderCreateReturnRequestPretensionTypeEnum } from "~/layers/ecom/enum";

export default () => {
  const storesStore = useStoresStore();
  const { currentServiceType } = storeToRefs(storesStore);
  const { $transport } = useNuxtApp();

  const requestCourier = (orderId: string) => {
    return $transport<IOrderCourierTrackingResponse>(ecomUrls.postCheckout.courier(orderId), {
      permissions: {
        jwt: true,
      },
    });
  };

  const requestReturnableItems = (orderId: string) => {
    return $transport<IDtoV2CartsAvailableToReturn>(ecomUrls.postCheckout.returnableItems(orderId), {
      permissions: {
        jwt: true,
      },
    });
  };

  const requestGetReturns = (orderId: string) => {
    return $transport<IDtoV2OrderReturns>(ecomUrls.postCheckout.returns(orderId), {
      permissions: {
        jwt: true,
      },
    });
  };

  const requestPostReturns = (orderId: string, body: IOrderCreateReturnRequest) => {
    return $transport<ISuccess>(ecomUrls.postCheckout.returns(orderId), {
      method: "POST",
      permissions: {
        jwt: true,
      },
      body,
    });
  };

  const requestOrdersActive = (page: number, limit: number) => {
    /**
     * todo App, lookingForCourier
     * limit: defaultValue
     */
    return $transport<IDtoV2OrdersListResponse>(ecomUrls.postCheckout.ordersActive, {
      permissions: {
        jwt: true,
      },
      params: {
        page,
        limit,
        service: currentServiceType.value,
      },
    });
  };

  const requestOrdersArchive = (page: number, limit: number) => {
    /**
     * todo App, lookingForCourier
     * limit: defaultValue
     */
    return $transport<IDtoV2OrdersListResponse>(ecomUrls.postCheckout.ordersArchive, {
      permissions: {
        jwt: true,
      },
      params: {
        page,
        limit,
        service: currentServiceType.value,
      },
    });
  };

  const requestCancelReasons = () => {
    return $transport<IDtoCancelReasonsResponse>(ecomUrls.postCheckout.cancelReasons, {
      permissions: {
        jwt: true,
      },
      params: {
        deliveryMethod: OrderCreateReturnRequestPretensionTypeEnum.Takeaway,
        service: currentServiceType.value,
      },
    });
  };

  const requestCancel = (orderId: string, cancelReason: string) => {
    return $transport<ISuccess>(ecomUrls.postCheckout.cancel(orderId), {
      permissions: {
        jwt: true,
      },
      params: {
        cancelReason,
      },
    });
  };

  const requestInfo = (orderId: string) => {
    return useTransport<IDtoV2OrderInfoResponse>(ecomUrls.postCheckout.info(orderId), {
      permissions: {
        jwt: true,
      },
    });
  };

  const requestReorder = (orderId: string) => {
    return $transport<IDtoV2OrderReorderResponse>(ecomUrls.postCheckout.reorder(orderId), {
      method: "POST",
      permissions: {
        jwt: true,
      },
    });
  };

  return {
    requestCourier,
    requestReturnableItems,
    requestGetReturns,
    requestPostReturns,
    requestOrdersActive,
    requestOrdersArchive,
    requestCancelReasons,
    requestCancel,
    requestInfo,
    requestReorder,
  };
};
