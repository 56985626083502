import NotLoad40 from "@magnit/icons/src/assets/svg/icons/v1/40-image-not-load.svg?url";
import StarIcon16 from "@magnit/icons/src/assets/svg/icons/base/16-system-star.svg";
import LoyaltyBonusWhite24 from "@magnit/icons/src/assets/svg/icons/base/24-loyalty-bonus-white.svg";

export const images: Record<string, string> = {
  "Base / 16 / System / Star": StarIcon16,
  "Base / 24 / Loyalty / Bonus / White": LoyaltyBonusWhite24,
};

export const imagesPath: Record<string, string> = {
  "Base / 40 / Image not load": NotLoad40,
};

export const contentScaleMap: Record<string, Record<string, string>> = {
  fit: {
    objectFit: "contain",
  },
};

export const defaultStyle = {
  margin: 0,
  width: "auto",
  boxSizing: "border-box",
  backgroundColor: "transparent",
};
