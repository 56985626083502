<script setup lang="ts">
import { vElementVisibility } from "@vueuse/components";
import VAppLink from "@magnit/core/src/components/VAppLink/VAppLink.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VWrapper from "@magnit/core/src/components/VWrapper/VWrapper.vue";
import type { IOwnBrand } from "~/typings/api/sections";

const { send } = useAnalytics();
const { requestOwnBrands } = sectionsApi();
const { status, data } = await requestOwnBrands();

const onVisibility = (v: boolean) => v && send("Main:MagnitOnly:View");
const onSlideVisibility = (v: boolean, item: IOwnBrand, idx: number) => {
  if (v) {
    send("Main:MagnitOnly:Item:View", collectOwnBrandsPayload({ item, idx }));
  }
};

const onSlideClick = (item: IOwnBrand, idx: number) => {
  send("Main:MagnitOnly:Item:Click", collectOwnBrandsPayload({ item, idx }));
};

const brandRoute = Routes.Brand;
</script>

<template>
  <SectionsOwnBrandsSkeleton v-if="status === 'pending'" />
  <section v-else-if="data && data.length" v-element-visibility="onVisibility" class="own-brands">
    <VWrapperLocal class="own-brands__title-wrapper">
      <VText tag="h2" :font="{ xs: 'headline-small', l: 'headline-large' }">
        Только в Mагнит
      </VText>
    </VWrapperLocal>
    <VWrapper :indent="{ l: '32px' }">
      <AppSlider
        :slides-per-view="'auto'"
        data-test-id="own-brands"
        :breakpoints="{
          xs: {
            slidesPerView: 'auto',
            navigation: false,
            spaceBetween: 8,
            slidesOffsetAfter: 12,
            slidesOffsetBefore: 12,
          },
          s: {
            spaceBetween: 12,
            slidesOffsetAfter: 20,
            slidesOffsetBefore: 20,
          },
          m: {
            spaceBetween: 20,
            slidesOffsetAfter: 32,
            slidesOffsetBefore: 32,
          },
          l: {
            slidesPerView: 4,
            spaceBetween: 24,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
            navigation: true,
          },
          xl: {
            slidesPerView: 6,
            spaceBetween: 24,
          },
        }"
      >
        <AppSliderSlide
          v-for="(item, idx) in data"
          :key="item.id"
          v-element-visibility="(arg) => onSlideVisibility(arg, item, idx)"
          :size="{ xs: 8, m: 4, ml: 3, l: 3, xl: 2 }"
        >
          <VAppLink
            :to="`${brandRoute}/${item.article.code}`"
            class="own-brands__link pl-hover-base"
            @click="onSlideClick(item, idx)"
          >
            <img :src="item.publicImageURL" :alt="item.name" :title="item.name" loading="lazy">
          </VAppLink>
        </AppSliderSlide>
      </AppSlider>
    </VWrapper>
  </section>
</template>

<style lang="postcss" scoped>
.own-brands {
  /* min-height прописаны чтобы уменьшить прыжки страницы пока картинки рендерит */
  min-height: 322px;

  @media (--pl-viewport-from-s) {
    min-height: 340px;
  }

  @media (--pl-viewport-from-m) {
    min-height: 272px;
  }

  @media (--pl-viewport-from-l) {
    min-height: 356px;
  }

  @media (--pl-viewport-from-xl) {
    min-height: 346px;
  }

  &__title-wrapper {
    padding-top: var(--pl-unit-x8);
    padding-bottom: var(--pl-unit-x4);
  }

  &__link {
    display: block;
    text-decoration: none;

    img {
      width: 100%;
    }
  }
}

@media (--pl-viewport-from-l) {
  .own-brands {
    &__title-wrapper {
      padding-top: 48px;
      padding-bottom: 16px;
    }
  }
}
</style>
