<script setup lang="ts">
import { vElementVisibility } from "@vueuse/components";
import VWrapper from "@magnit/core/src/components/VWrapper/VWrapper.vue";
import VAppLink from "@magnit/core/src/components/VAppLink/VAppLink.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import type { IEverGreenBanner } from "~/typings/api/sections";

const { send } = useAnalytics();
const { requestEverGreenBanners } = sectionsApi();
const { status, data: slides } = await requestEverGreenBanners();
const onVisibility = (v: boolean) => v && send("Main:CustomBanners:View");
const onSlideVisibility = (v: boolean, item: IEverGreenBanner, idx: number) => {
  if (v) {
    send("Main:CustomBanners:Item:View", collectEvergreenBannersPayload({ item, idx }));
  }
};

const onSlideClick = (item: IEverGreenBanner, idx: number) => {
  send("Main:CustomBanners:Item:Click", collectEvergreenBannersPayload({ item, idx }));
};
</script>

<template>
  <SectionsEverGreenBannersSkeleton v-if="status === 'pending'" />
  <section v-else-if="slides?.length" v-element-visibility="onVisibility" class="evergreen-banners">
    <VWrapper :indent="{ l: '32px' }">
      <AppSlider
        autoplay
        data-test-id="evergreen-banners"
        :breakpoints="{
          xs: {
            slidesPerView: 'auto',
            slidesOffsetAfter: 12,
            slidesOffsetBefore: 12,
            spaceBetween: 8,
            navigation: false,
          },
          s: {
            slidesPerView: 'auto',
            slidesOffsetAfter: 20,
            slidesOffsetBefore: 20,
            spaceBetween: 12,
            navigation: false,
          },
          m: {
            slidesPerView: 'auto',
            spaceBetween: 20,
            slidesOffsetAfter: 32,
            slidesOffsetBefore: 32,
          },
          ml: {
            slidesPerView: 3,
            spaceBetween: 20,
            slidesOffsetAfter: 32,
            slidesOffsetBefore: 32,
          },
          l: {
            slidesPerView: 4,
            spaceBetween: 24,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
          },
        }"
      >
        <AppSliderSlide
          v-for="(item, idx) in slides"
          :key="item.id"
          :size="{ xs: 'auto', m: 6, ml: 4, l: 3, xl: 3 }"
        >
          <VAppLink :to="item.internalLink" class="pl-hover-base" @click="onSlideClick(item, idx)">
            <div
              v-element-visibility="(arg) => onSlideVisibility(arg, item, idx)"
              class="evergreen-banners__content"
            >
              <img :src="item.publicImageURL" alt="" :title="item.title">
              <VText
                :font="{
                  xs: 'body-small-accent-low',
                  xl: 'body-large-accent-low',
                }"
                class="evergreen-banners__title"
              >
                {{ item.title }}
              </VText>
            </div>
          </VAppLink>
        </AppSliderSlide>
      </AppSlider>
    </VWrapper>
  </section>
</template>

<style lang="postcss" scoped>
.evergreen-banners {
  padding-top: var(--pl-unit-x8);

  /* min-height прописаны чтобы уменьшить прыжки страницы пока картинки рендерит */
  min-height: 200px;

  @media (--pl-viewport-from-m) {
    min-height: 166px;
  }

  @media (--pl-viewport-from-l) {
    padding-bottom: var(--pl-unit-x4);
    min-height: 190px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--pl-unit-x1);
    width: 296px;

    @media (--pl-viewport-from-s) {
      width: 320px;
    }

    @media (--pl-viewport-from-m) {
      width: auto;
    }

    img {
      width: 100%;
    }
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
